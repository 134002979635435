// Importing required libraries and styles for the Templates Page component
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TemplatesPage.css';

function TemplatesPage({ onTemplateSelect }) {
  const [templates, setTemplates] = useState([]); // Templates array
  const [newTemplate, setNewTemplate] = useState(''); // New template input

  // Fetch templates from the backend when the component loads
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('https://rollin-trades-announcement-app.onrender.com/Templates');
        setTemplates(response.data.templates || []);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  // Handle saving a new template
  const handleSaveTemplate = async () => {
    if (!newTemplate.trim()) return;

    try {
      const response = await axios.post('https://rollin-trades-announcement-app.onrender.com/templates', {
        text: newTemplate,
      });
      setTemplates([...templates, response.data.template]);
      setNewTemplate(''); // Clear input field
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  // Handle deleting a template
  const handleDeleteTemplate = async (id) => {
    try {
      await axios.delete(`https://rollin-trades-announcement-app.onrender.com/templates/${id}`);
      setTemplates(templates.filter((template) => template._id !== id));
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  // Handle template selection
  const handleTemplateClick = (text) => {
    onTemplateSelect(text);
  };

  return (
    <div className="templates-page">
      <h1>Manage Templates</h1>
      <div className="template-input">
        <textarea
          placeholder="Write your new template here..."
          value={newTemplate}
          onChange={(e) => setNewTemplate(e.target.value)}
        />
        <button onClick={handleSaveTemplate}>Save Template</button>
      </div>
      <div className="template-list">
        <h2>Saved Templates</h2>
        {templates.length > 0 ? (
          templates.map((template) => (
            <div key={template._id} className="template-item">
              <p onClick={() => handleTemplateClick(template.text)}>{template.text}</p>
              <button onClick={() => handleDeleteTemplate(template._id)}>Delete</button>
            </div>
          ))
        ) : (
          <p>No templates saved yet.</p>
        )}
      </div>
    </div>
  );
}

export default TemplatesPage;
